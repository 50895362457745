import { Injectable } from '@angular/core';

import { environment } from '@environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { jwtDecode } from "jwt-decode";
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TokenService {
  decodedToken: any;
  contentScroll = new Subject()
  constructor(
    private $http: HttpClient
  ) {
  }

  get value() {
    return localStorage.getItem(environment.tokenKey) || '';
  }
  set value(token: string) {
    localStorage.setItem(environment.tokenKey, token);
  }
  get token() {
    return localStorage.getItem(environment.tokenKey);
  }
  get hasValue(): boolean {
    return !!this.value;
  }

  get conferenceId() {
    return localStorage.getItem('conference')
 }

 triggerToken(value:any) {
  this.contentScroll.next(value)
 }

  verify(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      if (this.value) {
        const headers = new HttpHeaders({
          Authorization: `Bearer ${this.value}`
        });
        this.$http.head('/token', { headers })
          .subscribe(() => resolve(true), () => resolve(false));
      } else {
        resolve(false);
      }
    });
  }
 

  remove() {
    localStorage.removeItem(environment.tokenKey);
  }
  reset() {
    this.remove();
  }
  get header() {
    return `Bearer ${this.value}`;
  }

  getDecodeToken() {
    return jwtDecode(this.token || '');
  }
}
